<mat-card class="lesson-card" role="listitem" matRipple [style]="{backgroundColor: getColorCode(), color: getLetterColor()}">
  <mat-card-content [ngClass]="'rippleCorrection lesson-card-content'">
    <div class="lesson-card-row">
      <p>{{getCodeName()}}</p>
      <h3>{{getFullName()}}</h3>
      <mat-icon *ngIf="isOngoing()">track_changes</mat-icon>
    </div>
    <div class="lesson-card-row">
      <span>{{reservation !== undefined ? getFormattedTime(reservation.start) : ''}}-{{reservation !== undefined ? getFormattedTime(reservation.end) : ''}}</span>
      <span>{{getTeachers()}}</span>
      <span>{{getRooms()}}</span>
    </div>
  </mat-card-content>
</mat-card>
