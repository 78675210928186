


<mat-drawer-container [hasBackdrop]="mobileQuery.matches" style="height: 100%;" autosize class="wilmaplus-drawer">
  <mat-drawer #drawer [mode]="mobileQuery.matches ? 'over' : 'side'" [class]="(mobileQuery.matches || isExpanded) ? 'sidenav sidenav-fixed' : 'sidenav'" [disableClose]="!mobileQuery.matches" [opened]="!mobileQuery.matches">
    <div class="header" *ngIf="mobileQuery.matches ? true : isExpanded">
      <div class="actions">
        <button mat-icon-button (click)="openSelectorDialog()">
          <mat-icon>swap_horiz</mat-icon>
        </button>
      </div>
      <img class="profile-image" alt="Profile image" [src]="ui.profileImage ? getBase64Image(ui.profileImage) : '../../assets/profile_placeholder.jpg'"/>
      <h2>{{ui.name || appName}}</h2>
      <h3>{{(ui.name && ui.school) ? ui.school || version : ''}}</h3>
    </div>
    <mat-nav-list>
      <a mat-list-item (click)="navigateFromSideNav(page)" *ngFor="let page of pages">
        <mat-icon matListIcon>{{page.icon}}</mat-icon>
        <span mat-line *ngIf="mobileQuery.matches ? true : isExpanded">{{page.name | translate}}</span>
      </a>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
    <div class="client">
      <mat-toolbar color="primary" style="background: transparent;">
        <button mat-icon-button (click)="mobileQuery.matches ? drawer.toggle() : isExpanded = !isExpanded">
          <mat-icon>menu</mat-icon>
        </button>
        <span>{{pageTitle}}</span>
        <span class="toolbar-spacer"></span>
      </mat-toolbar>
      <router-outlet style="overflow-y: auto"></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
