<div class="messagebox" role="listitem" matRipple [routerLink]="getMessageURL()">
  <div class="messagebox-row">
    <div class="messagebox-unread-ball" *ngIf="unreadMessage()">

    </div>
    <div class="messagebox-recipient-ball" [style]="{backgroundColor: getColorCode()}">
      <span class="txt" [style]="{color: getLetterColor()}">{{this.senderName.substr(0, 1)}}</span>
    </div>
    <div class="messagebox-details">
      <h4><b>{{this.senderName}}</b></h4>
      <p>{{getMessageName()}}</p>
      <div class="messagebox-row bottom-info">
        <p>{{getMessageTimeStamp()}} <mat-icon style="font-size: 22px;" *ngIf="getConversationCount()>0">forum</mat-icon> {{getConversationCount()}}</p>
      </div>
    </div>
  </div>
</div>
