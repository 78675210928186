<mat-card class="desktop-card desktop-card-static">
  <mat-card-content class="user-info" align="center">
    <div class="user-info" align="center">
      <h1 translate [translateParams]="{name: ui.name}">welcome_user</h1>
      <h3>{{ui.type != null ? (ui.type | translate) : ''}}</h3>
      <img class="profile-image" alt="Profile image" [src]="ui.profileImage ? getBase64Image(ui.profileImage) : '../../assets/profile_placeholder.jpg'"/>
      <div class="date-box">
        <mat-icon>today</mat-icon><h2>{{getCurrentDateAndTime()}}</h2>
      </div>
    </div>
  </mat-card-content>
</mat-card>
