<div *ngIf="!mobile" class="grid-container grid-container--fill">
  <wilmaplus-card-badge [ui]="ui"></wilmaplus-card-badge>
  <wilmaplus-card-messages></wilmaplus-card-messages>
  <wilmaplus-card-schedule></wilmaplus-card-schedule>
</div>

<div class="homepage-container mobile" *ngIf="mobile">
  <mat-card class="mobile-card">
    <div class="user-info" align="center" #userHeader>
      <h1 translate [translateParams]="{name: ui.name}">welcome_user</h1>
      <h3>{{ui.type != null ? (ui.type | translate) : ''}}</h3>
      <img class="profile-image" alt="Profile image" [src]="ui.profileImage ? getBase64Image(ui.profileImage) : '../../assets/profile_placeholder.jpg'"/>
      <div class="date-box">
        <mat-icon>today</mat-icon><h2>{{getCurrentDateAndTime()}}</h2>
      </div>
    </div>
    <div class="section">
      <mat-tab-group color="accent" selectedIndex="0">
        <mat-tab *ngFor="let tab of tabs" class="">
          <ng-template mat-tab-label>
            <mat-icon [matBadge]="tab.badge" matBadgeColor="warn">{{tab.icon}}</mat-icon>
          </ng-template>
          <div class="list-container">
            <wilmaplus-tab-schedule *ngIf="tab.type==1" [userHeader]="userHeader"></wilmaplus-tab-schedule>
            <wilmaplus-tab-messages *ngIf="tab.type==2" [badgeCallback]="getMessagesCallback"></wilmaplus-tab-messages>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-card>
</div>
