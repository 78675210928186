<mat-progress-bar mode="indeterminate" *ngIf="loading" color="accent"></mat-progress-bar>
<div class="mfaSheet bottomSheetPadding">
  <h1>{{'mfa_sheet_title' | translate}}</h1>
  <p>{{'mfa_sheet_desc' | translate}}</p>
  <ng-otp-input #otpCode [config]="{length:6, allowNumbersOnly: true}" (onInputChange)="onOtpChange()" *ngIf="!loading"></ng-otp-input>
  <div class="warnBox" *ngIf="!loading && invalid">
    <mat-icon>error</mat-icon>
    <p>{{'invalid_mfa' | translate}}</p>
  </div>
  <button color="accent" mat-raised-button [disabled]="loading" (click)="changeAccount()">{{(otherAccountsFound ? 'change_account' : 'sign_out') | translate}}</button>
</div>
